import { useLocation } from '@solidjs/router';
import { createMemo, JSX } from 'solid-js';
import IconRentalApplicationBanner from '~/assets/backgrounds/rentalApplicationBanner.png';
import {
  APPLICANT_INFORMATION,
  APPLICATION_SUBMITTED,
  CONFIRM_YOUR_IDENTITY,
  MANUAL_VERIFICATION,
  PAYMENT_DETAILS,
  POTENTIAL_UNITS,
  QUESTIONAIRE,
  QUESTIONS,
  RENTAL_HISTORY,
  TERM_AND_CONDITIONS,
  WORK_HISTORY,
} from '~/assets/strings';
import { LeftStepper } from '~/components/common/forms/LeftStepper';
import { Panel } from '~/components/common/panels';
import { useLocalization } from '~/hooks/useLocalization';
import RentalApplicationHeader from '~/pages/rental-application/RentalApplicationHeader';

export interface LayoutProps {
  children: JSX.Element;
}

const Layout = (props: LayoutProps) => {
  const { t } = useLocalization();
  const location = useLocation();

  const steps = createMemo(() => [
    { name: 'applicant-information', label: t(APPLICANT_INFORMATION) },
    { name: 'potential-units', label: t(POTENTIAL_UNITS) },
    { name: 'rental-history', label: t(RENTAL_HISTORY) },
    { name: 'employment-income', label: t(WORK_HISTORY) },
    { name: 'rental-questions', label: t(QUESTIONS) },
    { name: 'terms-and-conditions', label: t(TERM_AND_CONDITIONS) },
    { name: 'payment', label: t(PAYMENT_DETAILS) },
    { name: 'transunion-connection', label: t(CONFIRM_YOUR_IDENTITY) },
    { name: 'questionnaire', label: t(QUESTIONAIRE) },
    { name: 'application-submitted', label: t(APPLICATION_SUBMITTED) },
    { name: 'manual-verification', label: t(MANUAL_VERIFICATION) },
  ]);

  const panelTitle = createMemo(() => {
    const path = location.pathname.split('/');
    const stepName = path[path.length - 1];
    const currentStep = steps().find((step) => step.name === stepName);
    return currentStep ? currentStep.label : '';
  });

  const condensedSteps = createMemo(() => [...steps().slice(0, -4), { name: 'transunion-connection', label: t(CONFIRM_YOUR_IDENTITY) }]);

  const currentStep = createMemo(() => {
    const path = location.pathname.split('/');
    const stepName = path[path.length - 1];
    const stepIndex = steps().findIndex((step) => step.name === stepName);

    if (stepIndex >= steps().length - 4) {
      return condensedSteps().length - 1;
    }
    return stepIndex;
  });

  return (
    <div class="flex h-screen flex-col overflow-y-auto bg-inputbox-bg">
      <div class="sticky top-0 z-50">
        <RentalApplicationHeader />
      </div>
      <div class="flex grow items-center justify-center">
        <div class="flex h-full w-11/12 gap-3 py-4">
          <Panel class="hidden h-fit w-[300px] text-2xl md:flex">
            <LeftStepper steps={condensedSteps().map((step) => step.label)} step={currentStep()} img={IconRentalApplicationBanner} />
          </Panel>
          <main class="h-fit w-full overflow-y-auto md:w-3/4">
            <Panel title={panelTitle()} class="mb-14 h-fit w-full">
              {props.children}
            </Panel>
          </main>
        </div>
      </div>
    </div>
  );
};
export default Layout;
